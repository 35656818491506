import { LANGUAGE_OPTION, OPEN_MODAL, isClient, isDev } from "@/app/configs"
import { AppContext } from "@/app/context"
import { useQueryProfileInfo } from "@/app/services/query-fn"
import React, { useCallback, useContext, useEffect } from "react"
import { ToasterContainer } from "../ui/toaster"
import { ModalContainer } from "./ModalContainer"
import { Header } from "../header"
import { SideBarMobile } from "./SideBarMobile"
import { ScrollToTop } from "../scroll-to-top/ScrollToTop"
import { Footer } from "../footer/Footer"
import useAxiosInterceptors from "@/app/hooks/useAxiosInterceptor"
import { http } from "@/app/api"
import { useRouter } from "next/router"
// import ThothanChatbot from "./ThoThanChatbot"
declare const window: any

type TLayout = {
  children: React.ReactNode | undefined
  breakLayout?: boolean
  showHeader?: boolean
  expandHeader?: boolean
  isShowSidebar?: boolean
  isHasFooter?: boolean
  isProfilePage?: boolean
}


export const Layout: React.FC<TLayout> = ({
  children,
  breakLayout = false,
  showHeader = true,
  expandHeader = false,
  isShowSidebar = true,
  isHasFooter = true,
  isProfilePage = false
}) => {
  const { data: profileInfo } = useQueryProfileInfo()
  const router = useRouter()
  const token = isClient ? window.localStorage.getItem("token") : null

  const { userInfo, setUserInfo, setThemeApp, themeApp, setOpenModal } =
    useContext(AppContext)
  useAxiosInterceptors(http)

  const isHiddenScroll = [
    "/event/dua-top-moi-ban/[id]",
    "/event/landing/[id]"
  ].includes(router.pathname)

  useEffect(() => {
    if (!!profileInfo?.data?.data && !!token) {
      setUserInfo(profileInfo?.data?.data)
      localStorage.setItem(
        "language",
        profileInfo?.data?.data?.lang ?? LANGUAGE_OPTION.VIETNAMESE
      )

      router.push(
        {
          pathname: router.pathname,
          query: router.query
        },
        router.asPath,
        {
          locale: profileInfo?.data?.data?.lang || LANGUAGE_OPTION.VIETNAMESE
        }
      )
    }
  }, [profileInfo, token])

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      const _theme = localStorage.getItem("theme")
      if (_theme === "dark") {
        setThemeApp("dark")
        document.documentElement.classList.add("dark")
      } else {
        setThemeApp("light")
        document.documentElement.classList.remove("dark")
      }
    }
  }, [themeApp])

  // Auto open modal edit hashtag if not select any hashtag
  useEffect(() => {
    if (
      !!userInfo &&
      userInfo?.topic_interest &&
      userInfo?.games_interest &&
      userInfo?.topic_interest?.length + userInfo?.games_interest?.length === 0
    ) {
      setOpenModal(OPEN_MODAL.EDIT_HASHTAG)
    }
  }, [userInfo])

  useEffect(() => {
    const refCode = router?.query?.referent_code
    if (!!refCode && !token) {
      setOpenModal(OPEN_MODAL.LOGIN_MODAL)
    }
    if (
      !!refCode &&
      !!token &&
      !!userInfo?.userid &&
      !userInfo?.invite_code &&
      refCode !== userInfo?.referent_code
    ) {
      setOpenModal(OPEN_MODAL.SHARE_REFER_CODE)
    }
  }, [router, token, userInfo])

  useEffect(() => {
    const button = document.getElementById("thothan-chatbot-bubble-button")
    if (!token && button && !userInfo?.userid) {
      button.style.display = "none"
    }

    if (token && button && userInfo?.userid) {
      button.style.display = "block"
    }
  }, [token, userInfo])


  return (
    <>
      <div
        id="layout"
        className={`min-h-[100dvh] relative overflow-hidden ${
          isShowSidebar && (isProfilePage ? "" : "pb-[100px] md:pb-[56px]")
        } md:mb-0`}
      >
        {/* { token && userInfo && <ThothanChatbot userName={userInfo?.fullname} userToken={token}/> } */}

         {/* <noscript> */}
         <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-W2LT6G74"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
        {/* </noscript> */}

       
        <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/latest/TweenMax.min.js"></script>
        {showHeader && <Header expandHeader={expandHeader} />}
        <div
          className={`${
            !breakLayout &&
            `max-w-[1280px] m-auto px-[16px] md:px-[48px] ${
              showHeader && "pt-[84px]"
            } sm:px-[30px] pc:px-0`
          }`}
        >
          {children}
          {!isHiddenScroll && <ScrollToTop />}
        </div>
        {isShowSidebar && <SideBarMobile />}

        {/* toast message container */}
        <ToasterContainer />

        {/* modal container */}
        <ModalContainer />
      </div>
      {isHasFooter && <Footer />}
    </>
  )
}
