import { Layout } from "@/app/components/layout"
import { defaultOptions, isDev, meta } from "@/app/configs"
import { http } from "@api"
import interceptor from "@api/interceptor"
import IMetaPage from "@interfaces/IMetaPage"
import "@styles/globals.css"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { AppProvider, MobileProvider, ThemeProvider } from "app/context"
import { appWithTranslation } from "next-i18next"
import type { AppProps } from "next/app"
import Head from "next/head"
import React from "react"
import { Suspense } from "react"
import "tailwindcss/tailwind.css"
import "swiper/css"
import "swiper/css/pagination"
import { RewardProvider } from "@/app/context/RewardContext"
import useAxiosInterceptors from "@/app/hooks/useAxiosInterceptor"

// interceptor(http)

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const metaPage: IMetaPage = pageProps.metaPage ?? null
  const breakLayout = pageProps?.breakLayout ?? ""
  const showHeader = pageProps?.showHeader ?? true
  const expandHeader = pageProps?.expandHeader ?? false
  const isShowSidebar = pageProps?.isShowSidebar ?? true
  const isHasFooter = pageProps?.isHasFooter ?? true
  const isProfilePage = pageProps?.isProfilePage ?? false
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions
      })
  )

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta httpEquiv="content-language" content="en" />
        <meta name="robots" content="all" />
        <title>
          {metaPage && metaPage?.title !== "" ? metaPage?.title : meta.title}
        </title>
        <meta
          name="title"
          content={
            metaPage && metaPage?.title !== "" ? metaPage?.title : meta.title
          }
        />
        <meta
          name="description"
          content={
            metaPage && metaPage?.desc !== "" ? metaPage?.desc : meta.desc
          }
        />
        <meta
          name="keywords"
          content={
            metaPage && metaPage?.keyword !== ""
              ? metaPage?.keyword
              : meta.keyword
          }
        />
        <meta
          name="generator"
          content={
            metaPage && metaPage?.title !== "" ? metaPage?.title : meta.title
          }
        />
        <meta
          property="og:url"
          content={metaPage && metaPage?.url !== "" ? metaPage?.url : meta.url}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            metaPage && metaPage?.title !== "" ? metaPage?.title : meta.title
          }
        />
        <meta
          property="og:description"
          content={
            metaPage && metaPage?.desc !== "" ? metaPage?.desc : meta.desc
          }
        />
        <meta
          property="og:site_name"
          content={
            metaPage && metaPage?.site_name !== ""
              ? metaPage?.site_name
              : meta.site_name
          }
        />
        <meta
          property="og:image"
          content={
            metaPage && metaPage?.image !== "" ? metaPage?.image : meta.image
          }
        />
        <meta
          property="twitter:title"
          content={
            metaPage && metaPage?.title !== "" ? metaPage?.title : meta.title
          }
        />
        <meta
          property="twitter:site"
          content={
            metaPage && metaPage?.site_name !== ""
              ? metaPage?.site_name
              : meta.site_name
          }
        />
        <meta
          property="twitter:description"
          content={
            metaPage && metaPage?.desc !== "" ? metaPage?.desc : meta.desc
          }
        />
        <meta
          property="twitter:image"
          content={
            metaPage && metaPage?.image !== "" ? metaPage?.image : meta.image
          }
        />
        <meta
          property="twitter:image:src"
          content={
            metaPage && metaPage?.image !== "" ? metaPage?.image : meta.image
          }
        />
        <link
          rel="canonical"
          href={
            metaPage && metaPage?.canonical !== ""
              ? metaPage?.canonical
              : meta.canonical
          }
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="icon" type="image/png" href="/img/playee_logo.png" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/img/playee_logo.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href="/img/playee_logo.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/playee_logo.png"
        />
      </Head>
      <Suspense>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider
            attribute="class"
            storageKey="theme"
            defaultTheme="light"
            enableSystem
            themes={["light", "dark"]}
            disableTransitionOnChange
          >
            <AppProvider>
              <MobileProvider>
                <RewardProvider>
                  <Layout
                    breakLayout={breakLayout}
                    showHeader={showHeader}
                    expandHeader={expandHeader}
                    isShowSidebar={isShowSidebar}
                    isHasFooter={isHasFooter}
                    isProfilePage={isProfilePage}
                  >
                    <Component {...pageProps} />
                  </Layout>
                </RewardProvider>
              </MobileProvider>
            </AppProvider>
          </ThemeProvider>

          {/* {isDev && (
            <ReactQueryDevtools initialIsOpen={false} client={queryClient} />
          )} */}
        </QueryClientProvider>
      </Suspense>
    </>
  )
}

export default appWithTranslation(MyApp)
