import { DATE_FORMAT_FULL } from "@/app/configs"
import moment from "moment"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React from "react"
import { enumViewDetailTransaction } from "../../tab"

export const ItemCardTransaction = ({
  item,
  onSelecteData
}: {
  item: any
  onSelecteData: (item: any) => void
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const onNavigateToDetail = () => {
    const type = String(router?.query?.type)
    onSelecteData(item)
    router.push({
      pathname: router.pathname,
      query: {
        id: router?.query?.id,
        tab: router?.query?.tab,
        type:
          type === "undefined"
            ? enumViewDetailTransaction.CARD_DETAIL
            : `${type?.replace("-all", "")}-detail`
      }
    })
  }

  return (
    <div
      className="p-[12px] bg-white dark:bg-white/10 rounded-[16px] w-full cursor-pointer border border-black/10 hover:border-main-01 dark:border-0 space-y-[12px]"
      onClick={onNavigateToDetail}
    >
      <div className="flex items-center justify-between">
        <span className="text-xs text-neutral-06 Nunito-400 dark:text-neutral-08">
          {moment.unix(item?.created_at).format(DATE_FORMAT_FULL)}
        </span>
        {!item?.items?.length ? (
          <p className=" text-[#F28585] text-[12px] Nunito-600 rounded-[8px] w-fit line-clamp-1">
            {t("store.wait_export_card")}
          </p>
        ) : item?.items?.every((item: any) => !item?.is_used) ? (
          <p className=" text-[#F28585] text-[12px] Nunito-600 rounded-[8px] w-fit line-clamp-1">
            {t("common.available")}
          </p>
        ) : (
          <p className=" text-[#F28585] text-[12px] Nunito-600 rounded-[8px] w-fit line-clamp-1">
            {t("reward.used")}
          </p>
        )}
      </div>

      <div className="flex gap-1 sm:flex-row rounded-[8px] w-full">
        <img
          src={item?.item_icon}
          alt={item?.item_name}
          className="w-[55px] h-[55px] rounded-[8px] mr-[13px] object-cover"
        />
        <div className="flex-1">
          <div className="flex items-center justify-between w-full mb-1">
            <p className="text-[14px] Nunito-600 line-clamp-1">
              {item?.item_name}
            </p>
          </div>

          <p className="text-[12px] Nunito-400 text-neutral-06 dark:text-neutral-08 line-clamp-1 break-all">
            {t("profile.item_code_summary")}: {item?.transaction_id}
          </p>
          <p className="text-[12px] dark:text-neutral-08">
            x{item?.item_quantity}
          </p>
        </div>
      </div>
      <div className="border-t-[1px] border-dashed border-[rgba(0, 0, 0, 0.10)] flex flex-col gap-[8px] pt-[12px]">
        <div className="flex items-center justify-between gap-2">
          <p className="text-xs text-neutral-06 dark:text-neutral-08">
            {t("store.total")}:
          </p>
          <span className="text-sm text-main-01 Nunito-700 dark:text-main-red-02">
            {item?.amount?.toLocaleString()}&nbsp;đ
          </span>
        </div>
        {/* <div className="flex items-center justify-between gap-2">
          <p className="text-xs Nunito-400 text-neutral-06 dark:text-neutral-08">
            {t("store.buy_date")}
          </p>
          
        </div> */}
      </div>
    </div>
  )
}
