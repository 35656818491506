import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { QUERY_KEY } from "@/app/configs"
import { PackageContext } from "@/app/context/PackageContext"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import React, { FC, useContext } from "react"

interface ModalSendSuccessProps {
  open: boolean
  handleClose: () => void
  resetQuery: () => void
}

export const ModalSendSuccess: FC<ModalSendSuccessProps> = ({
  open,
  handleClose,
  resetQuery
}) => {
  const queryClient = useQueryClient()
  const {
    visible,
    setVisible,
    dataSelected,
    setDataSelected,
    selectedFriend,
    setSelectedFriend
  } = useContext(PackageContext)
  const { t } = useTranslation()

  console.log(selectedFriend)

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-[320px] border-0 shadow-[0px_4px_30px_0px_rgba(0,0,0,0.06)] rounded-[24px] p-[24px] sm:rounded-[24px] gap-0 xs:w-[90vw]">
        <div
          className={`w-[80px] h-[80px] rounded-[80px] mx-auto flex items-center justify-center mb-[24px] bg-bg-green`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M19.9999 3.33337C10.8166 3.33337 3.33324 10.8167 3.33324 20C3.33324 29.1834 10.8166 36.6667 19.9999 36.6667C29.1832 36.6667 36.6666 29.1834 36.6666 20C36.6666 10.8167 29.1832 3.33337 19.9999 3.33337ZM27.9666 16.1667L18.5166 25.6167C18.2832 25.85 17.9666 25.9834 17.6332 25.9834C17.2999 25.9834 16.9832 25.85 16.7499 25.6167L12.0332 20.9C11.5499 20.4167 11.5499 19.6167 12.0332 19.1334C12.5166 18.65 13.3166 18.65 13.7999 19.1334L17.6332 22.9667L26.1999 14.4C26.6832 13.9167 27.4832 13.9167 27.9666 14.4C28.4499 14.8834 28.4499 15.6667 27.9666 16.1667Z"
              fill="#27908C"
            />
          </svg>
        </div>

        <p className="text-[16px] Nunito-700 pb-[8px] leading-[22px] text-center">
          Tặng quà thành công
        </p>
        <p className="text-[14px] text-neutral-05 text-center dark:text-neutral-07">
          Quà đã đươc chuyển tới {selectedFriend?.fullname}
        </p>
        <Button
          variant="main"
          onClick={() => {
            setVisible({
              ...visible,
              sendSuccess: false
            })
            setDataSelected({})
            resetQuery()
            setSelectedFriend(undefined)
          }}
          className="text-[14px] leading-[19px] h-[40px] Nunito-700 rounded-[16px] px-[24px] py-[10px] w-fit mx-auto mt-[20px]"
        >
          {t("common.close")}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
