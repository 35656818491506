import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { FC, useEffect, useState } from "react"
import {
  ItemDetailMerchandiseMobile,
  ItemDetailMerchandiseWeb
} from "./ItemDetailMerchandise"
import { IconCopied } from "@/app/components/icon-tsx/IconCopied"
import { IconNonCopy } from "@/app/components/icon-tsx/IconNonCopy"
import moment from "moment"
import {
  DATE_FORMAT_3,
  DATE_FORMAT_FULL,
  _15M_TIME_STAMP,
  _24H_TIME_STAMP
} from "@/app/configs"
import { Button } from "@/app/components/ui/button"
import { ModalCancelMerchandise } from "./ModalCancelMerchandise"
import { useQueryGetMerchandisePaymentTransaction } from "@/app/services/query-fn/merchandise/merchandise-query"

interface DetailProps {
  dataSelected: any
  setDataSelected: (param: any) => void
}

const LIMIT_LENGTH = 2

export const MerchandiseDetail: FC<DetailProps> = ({
  dataSelected,
  setDataSelected
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const [copyTransaction, setCopyTransaction] = useState(false)
  const [visible, setVisible] = useState(false)
  const { data } = useQueryGetMerchandisePaymentTransaction({
    transaction_id: dataSelected?.transaction_id
  })
  const [expand, setExpand] = useState(false)

  const [listHook, setListHook] = useState<any[]>([])

  const onCopyTransaction = () => {
    if (!copyTransaction) {
      navigator.clipboard.writeText(dataSelected?.transaction_id)
      setCopyTransaction(true)
    }
  }

  useEffect(() => {
    if (copyTransaction) {
      setTimeout(() => {
        setCopyTransaction(false)
      }, 2000)
    }
    return () => {
      clearTimeout(
        setTimeout(() => {
          setCopyTransaction(false)
        }, 2000)
      )
    }
  }, [copyTransaction])

  useEffect(() => {
    if (data?.data?.ghtk_hook_data?.length > 0) {
      setListHook(data?.data?.ghtk_hook_data)
    }
  }, [data])

  const status = {
    pending: {
      label: t("profile.pending_confirm"),
      value: "pending",
      bgColor: "#F28585",
      color: "#FAF8FF"
    },
    confirmed: {
      label: t("profile.transaction_confirmed"),
      value: "confirmed",
      bgColor: "#83ACEA",
      color: "#FAF8FF"
    },
    transfer: {
      label: t("profile.transaction_transfer"),
      value: "transfer",
      bgColor: "#FFB763",
      color: "#FAF8FF"
    },
    done: {
      label: t("profile.transaction_done"),
      value: "done",
      bgColor: "#ECECEE",
      color: "#28253E"
    },
    rejected: {
      label: t("profile.transaction_cancel"),
      value: "rejected",
      bgColor: "#E2526F",
      color: "#FAF8FF"
    },
    cancel: {
      label: t("profile.transaction_cancel"),
      value: "cancel",
      bgColor: "#E2526F",
      color: "#FAF8FF"
    },
    destroy: {
      label: t("profile.transaction_cancel"),
      value: "destroy"
    },
    refund: {
      label: t("profile.transaction_cancel"),
      value: "refund"
    }
  }

  const renderItemInfo = (item: any) => {
    return (
      <>
        <ItemDetailMerchandiseWeb dataSelected={dataSelected} item={item} />
        <ItemDetailMerchandiseMobile dataSelected={dataSelected} item={item} />
      </>
    )
  }
  const renderOrderAmount = () => {
    return (
      <div className="rounded-[5px] py-[24px]">
        {dataSelected?.status === "pending" &&
          (Number(dataSelected?.created_at) + _15M_TIME_STAMP) * 1000 >
            Number(moment().unix()) * 1000 && (
            <p className="text-sm Nunito-500 text-main-red-02 Nunito-400 mb-[24px]">
              {t("profile.remind_pending_status", {
                time: moment(
                  (Number(dataSelected?.created_at) + _15M_TIME_STAMP) * 1000
                ).format(DATE_FORMAT_FULL)
              })}
            </p>
          )}
        <div className="ml-auto flex flex-col gap-[12px]">
          {/* {data?.data?.ghtk_status_message && (
            <div className="flex justify-between">
              <p className="text-neutral-05 dark:text-neutral-08 text-[14px]">
                {t("profile.item_code")}
              </p>
              <div className="flex items-center gap-2">
                <p className="text-[14px] text-bg-01 dark:text-main-white Nunito-600">
                  {dataSelected?.transaction_id}
                </p>
                <div className="w-[1px] h-[17px] bg-neutral-08" />
                <span className="cursor-pointer" onClick={onCopyTransaction}>
                  {copyTransaction ? <IconCopied /> : <IconNonCopy />}
                </span>
              </div>
            </div>
          )} */}

          <div className="flex justify-between">
            <p className="text-neutral-05 dark:text-neutral-08 text-[14px]">
              {t("store.total_amount")}
            </p>
            <p className="text-[14px] text-bg-01 dark:text-main-white Nunito-600">
              {dataSelected?.amount?.toLocaleString()} đ
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-neutral-05 dark:text-neutral-08 text-[14px]">
              {t("merchandise.shipping_fee_title")}
            </p>
            <p className="text-[14px] text-bg-01 dark:text-main-white Nunito-600">
              {dataSelected?.fee_ship?.toLocaleString()} đ
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-neutral-05 dark:text-neutral-08 text-[14px]">
              {t("store.sale_off")}
            </p>
            <p className="text-[14px] text-bg-01 dark:text-main-white Nunito-600">
              {Number(
                dataSelected?.discount_voucher_amount +
                  dataSelected?.free_ship_voucher_amount +
                  dataSelected?.voucher_amount
              )?.toLocaleString()}
              &nbsp;đ
            </p>
          </div>

          <div className="flex justify-between">
            <p className="text-neutral-05 dark:text-neutral-08 text-[14px]">
              {t("store.payment_method")}
            </p>
            <p className="text-[14px] text-bg-01 dark:text-main-white Nunito-600">
              {dataSelected?.method}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-neutral-05 dark:text-neutral-08 text-[14px]">
              {t("store.total")}
            </p>
            <p className="text-[16px] text-main-01 Nunito-700">
              {Number(dataSelected?.total_amount)?.toLocaleString()}&nbsp;đ
            </p>
          </div>
        </div>
      </div>
    )
  }

  const renderStatusTransaction = () => {
    switch (dataSelected?.status) {
      case "cancel": {
        return (
          <p
            className={`text-[12px] Nunito-600 px-[10px] py-[2px] rounded-[8px] line-clamp-1 text-label-02 bg-bg-button dark:bg-white/10 h-[24px]`}
          >
            {t("profile.transaction_cancel")}
          </p>
        )
      }
      case "pending": {
        return (
          <p
            className={`text-[12px] Nunito-600 px-[10px] py-[2px] rounded-[8px] line-clamp-1 text-label-02 bg-bg-button dark:bg-white/10 h-[24px]`}
          >
            {t("profile.transaction_pending")}
          </p>
        )
      }
      default: {
        return (
          <p
            className={`text-[12px] Nunito-600 px-[10px] py-[2px] rounded-[8px] w-fit text-label-02 bg-bg-button dark:bg-white/10 h-[24px]`}
          >
            {dataSelected?.ghtk_status === -1
              ? status.cancel.label
              : status[
                  dataSelected?.list_item?.[0]?.status as keyof typeof status
                ]?.label}
          </p>
        )
      }
    }
  }

  const renderAction = () => {
    return (
      <div className="flex justify-end gap-4 !mb-[64px]">
        {(dataSelected?.status === "pending" ||
          dataSelected?.list_item?.[0]?.status === "pending") && (
          <Button
            variant="grey"
            className="text-[14px] px-[20px] text-neutral-03 dark:text-main-white"
            onClick={() => {
              setVisible(true)
            }}
          >
            {t("profile.cancel_merchandise")}
          </Button>
        )}
        {dataSelected?.status === "pending" &&
          dataSelected?.payment_link &&
          (Number(dataSelected?.created_at) + _15M_TIME_STAMP) * 1000 >
            Number(moment().unix()) * 1000 && (
            <Button
              variant="main"
              className="text-[14px] px-[20px]"
              onClick={() => {
                window.location.href = dataSelected?.payment_link
              }}
            >
              {t("merchandise.payment_now")}
            </Button>
          )}
      </div>
    )
  }

  const renderDeliveryStatus = () => {
    return data?.data?.ghtk_status_message ? (
      <div className="flex gap-[24px] pb-[24px] border-b border-dashed mb-[24px] md:flex-row flex-col">
        <div className=" md:w-[30%] space-y-[24px]">
          <div className="flex gap-[8px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
            >
              <g opacity="0.4">
                <path
                  d="M15.5 8.0744C15.5 12.1654 10.8125 17.3337 8 17.3337C5.1875 17.3337 0.5 12.1654 0.5 8.0744C0.5 3.9834 3.85786 0.666992 8 0.666992C12.1421 0.666992 15.5 3.9834 15.5 8.0744Z"
                  fill="#7673E6"
                />
              </g>
              <ellipse cx="8" cy="8.16699" rx="2.5" ry="2.5" fill="#7673E6" />
            </svg>
            <div>
              <p className="text-[14px] Nunito-600 pb-[12px]">
                {t("merchandise.merchandise_address")}
              </p>
              <p className="text-neutral-04 text-[12px] dark:text-neutral-08">
                {data?.data?.receiver_address?.fullname}
              </p>
              <p className="text-neutral-04 text-[12px] dark:text-neutral-08">
                {data?.data?.receiver_address?.phone}
              </p>
              <p className="text-neutral-04 text-[12px] dark:text-neutral-08">
                {data?.data?.receiver_address?.address},{" "}
                {data?.data?.receiver_address?.ward},{" "}
                {data?.data?.receiver_address?.district},{" "}
                {data?.data?.receiver_address?.city}
              </p>
            </div>
          </div>
          {data?.data?.ghtk_status !== -1 && (
            <div className="flex gap-[8px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <circle
                  opacity="0.4"
                  cx="9.0013"
                  cy="9.00033"
                  r="8.33333"
                  fill="#7673E6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.76562 5.06055C9.1108 5.06055 9.39062 5.34037 9.39062 5.68555V9.40174L11.4633 10.0926C11.7907 10.2018 11.9677 10.5557 11.8586 10.8832C11.7494 11.2107 11.3954 11.3876 11.068 11.2785L8.56798 10.4451C8.31277 10.3601 8.14062 10.1212 8.14062 9.85221V5.68555C8.14062 5.34037 8.42045 5.06055 8.76562 5.06055Z"
                  fill="#7673E6"
                />
              </svg>
              <div>
                <p className="text-[14px] Nunito-600">
                  {t("merchandise.estimate_delivery")}
                </p>
                <p className="pt-1 text-main-01 Nunito-500 text-[14px]">
                  {data?.data?.estimated_deliver_time}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="flex-1 w-full">
          <div
            className={`flex gap-[8px] ${
              data?.data?.ghtk_status === -1 && "mb-4"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M7.33464 0.5H4.0013C2.16035 0.5 0.667969 1.99238 0.667969 3.83333V10.5C0.667969 12.0544 1.73186 13.3602 3.17117 13.7291C3.22496 12.8572 3.94915 12.1667 4.83464 12.1667C5.75511 12.1667 6.5013 12.9129 6.5013 13.8333H10.668V3.83333C10.668 1.99238 9.17558 0.5 7.33464 0.5Z"
                fill="#7673E6"
              />
              <path
                d="M10.668 13.833V3.83301H13.3422C13.7657 3.83301 14.1732 3.99418 14.4821 4.28378L16.8079 6.46417C17.144 6.77924 17.3346 7.21938 17.3346 7.68006V12.1663C17.3346 13.0868 16.5884 13.833 15.668 13.833H10.668Z"
                fill="#7673E6"
              />
              <path
                d="M6.91667 13.8333C6.91667 14.9839 5.98393 15.9167 4.83333 15.9167C3.68274 15.9167 2.75 14.9839 2.75 13.8333C2.75 13.7896 2.75135 13.7462 2.754 13.7031C2.82123 12.6132 3.72648 11.75 4.83333 11.75C5.98393 11.75 6.91667 12.6827 6.91667 13.8333Z"
                fill="#7673E6"
              />
              <path
                opacity="0.4"
                d="M16.0808 13.8333C16.0808 14.9839 15.1481 15.9167 13.9975 15.9167C12.8469 15.9167 11.9141 14.9839 11.9141 13.8333C11.9141 13.7896 11.9155 13.7462 11.9181 13.7031C11.9854 12.6132 12.8906 11.75 13.9975 11.75C15.1481 11.75 16.0808 12.6827 16.0808 13.8333Z"
                fill="#7673E6"
              />
            </svg>
            <div className="flex flex-1 sd:flex-row flex-col sd:mb-0">
              <p className="text-[14px] Nunito-600">
                {t("merchandise.shipping_infomation")}
              </p>
              {data?.data?.ghtk_status !== -1 && (
                <div className="sd:ml-auto mr-0 text-neutral-04 dark:text-neutral-08 text-[12px]">
                  <p>Giao hàng tiết kiệm</p>
                  <p>{data?.data?.ghtk_hook_data?.[0]?.label_id}</p>
                </div>
              )}
            </div>
          </div>

          <div className="pl-[26px] sd:pl-0">
            {[
              ...listHook,
              {
                action_time: dataSelected?.created_at,
                ghtk_status_message: t("profile.order_successful"),
                type: -33
              }
            ]
              ?.slice(0, expand ? 100 : LIMIT_LENGTH)
              .map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="flex gap-[12px] sd:gap-[20px] pb-[18px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="7"
                      viewBox="0 0 6 7"
                      fill="none"
                      className="mt-[5px]"
                    >
                      <circle
                        cx="3"
                        cy="3.5"
                        r="3"
                        fill={index === 0 ? "#27908C" : "#878693"}
                      />
                    </svg>
                    <p
                      className={`text-[14px] Nunito-500 leading-[19px] ${
                        listHook?.length > 2 &&
                        index > 0 &&
                        !expand &&
                        "text-neutral-06"
                      }`}
                    >
                      {item.type === -33
                        ? moment.unix(item?.action_time)?.format(DATE_FORMAT_3)
                        : moment.utc(item?.action_time)?.format(DATE_FORMAT_3)}
                    </p>
                    <div>
                      <p
                        className={`leading-[19px] text-[14px] Nunito-500 ${
                          index === 0 && "text-main-green"
                        } ${
                          listHook?.length > 2 &&
                          index > 0 &&
                          !expand &&
                          "text-neutral-06"
                        }`}
                      >
                        {item?.ghtk_status_message}
                      </p>
                      <p
                        className={`leading-[19px] text-[14px] text-neutral-04 ${
                          listHook?.length > 2 &&
                          index > 0 &&
                          !expand &&
                          "text-neutral-08"
                        }`}
                      >
                        {item?.reason}
                      </p>
                    </div>
                  </div>
                )
              })}
          </div>
          {listHook?.length > 1 && (
            <div
              className="text-main-01 cursor-pointer text-[12px] Nunito-500 md:pl-[24px]"
              onClick={() => {
                setExpand(!expand)
              }}
            >
              {expand ? t("common.view_less") : t("common.view_all")}
            </div>
          )}
        </div>
      </div>
    ) : null
  }

  return (
    <div className="w-full m-auto space-y-[12px] md:space-y-0">
      <div className="w-full  pb-[24px] border-b border-dashed">
        <div className="flex items-center mb-4 gap-[10px] flex-wrap xs:justify-between">
          <div className="bg-bg-button dark:bg-white/10 px-[10px] rounded-[8px] flex h-[26px] items-center gap-[10px]">
            <p className="text-bg-01  dark:text-white text-[12px] Nunito-500 ">
              {t("profile.item_code_summary")}:&nbsp;
              <span className="Nunito-700">{dataSelected?.transaction_id}</span>
            </p>
            <div className="w-[1px] h-[17px] bg-neutral-08" />
            <span className="cursor-pointer" onClick={onCopyTransaction}>
              {copyTransaction ? <IconCopied /> : <IconNonCopy />}
            </span>
          </div>

          {renderStatusTransaction()}
        </div>
        {renderDeliveryStatus()}

        <div className="w-full space-y-[12px] md:space-y-[10px]">
          {dataSelected?.list_item?.map((item: any, index: number) =>
            renderItemInfo(item)
          )}
        </div>
      </div>
      {renderOrderAmount()}
      {renderAction()}
      <ModalCancelMerchandise
        open={visible}
        onOpenChange={() => setVisible(false)}
        dataSelected={dataSelected}
      />
    </div>
  )
}
