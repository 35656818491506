import { AxiosResponse } from "axios"
import IApiResponse from "@interfaces/IApiResponse"
import { http } from "@/app/api"
import {
  IAddToCartRequest,
  ICategoryMerchandise,
  ICheckVoucherMerchandise,
  ICreateAddress,
  ICreatePaymentMerchandise,
  ICreateQuickPaymentMerchandise,
  IGameMerchandise,
  IGetListMerchandiseParams,
  IMerchandise,
  IMerchandiseAddress,
  IMerchandiseCart,
  IMerchandiseMethod,
  IPramsDistroyOrder,
  IPramsPaymentFeeShip,
  IPramsPaymentHistory,
  IUpdateCartRequest,
  IUpdateMerchandiseAddress,
  IUpdateQuickCartRequest
} from "@/app/interfaces"
import { removeNullKeyObject } from "@/app/lib/utils"

const resource = "/shops"

export class MerchandiseService {
  static createAddress(
    body: ICreateAddress
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/payment/address/create`, body)
  }

  static deleteAddress(id: string): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/payment/address/delete`, { id })
  }

  static updateAddress(
    body: IUpdateMerchandiseAddress
  ): Promise<AxiosResponse<IApiResponse>> {
    const _body = removeNullKeyObject(body)
    return http.post(`${resource}/payment/address/update`, _body)
  }

  static getListMerchandise(
    params: IGetListMerchandiseParams
  ): Promise<AxiosResponse<any>> {
    return http.get(`${resource}/items/list`, { params })
  }

  static getListCategory(): Promise<
    AxiosResponse<IApiResponse<ICategoryMerchandise[]>>
  > {
    return http.get(`${resource}/items/category`)
  }

  static getListGame(): Promise<
    AxiosResponse<IApiResponse<IGameMerchandise[]>>
  > {
    return http.get(`${resource}/items/games`)
  }

  static getListCart(): Promise<
    AxiosResponse<IApiResponse<IMerchandiseCart[]>>
  > {
    return http.get(`${resource}/items/listCards`)
  }

  static getMerchandiseDetail(
    id: string
  ): Promise<AxiosResponse<IApiResponse<IMerchandise>>> {
    return http.get(`${resource}/items/detail`, {
      params: {
        id
      }
    })
  }

  static addToCart(
    body: IAddToCartRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    const _body = removeNullKeyObject(body)
    return http.post(`${resource}/items/addCards`, _body)
  }

  static buyNow(body: IAddToCartRequest): Promise<AxiosResponse<IApiResponse>> {
    const _body = removeNullKeyObject(body)
    return http.post(`${resource}/items/quickBuy`, _body)
  }

  static updateCart(
    body: IUpdateCartRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    const _body = removeNullKeyObject(body)
    return http.post(`${resource}/items/updateCards`, _body)
  }

  static updateQuickCart(
    body: IUpdateQuickCartRequest
  ): Promise<AxiosResponse<IApiResponse>> {
    const _body = removeNullKeyObject(body)
    return http.post(`${resource}/items/updateQuickBuyInfo`, _body)
  }

  static deleteCart(id: string): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/items/deleteCards`, { id })
  }

  static getMerchandiseMethod(): Promise<
    AxiosResponse<IApiResponse<IMerchandiseMethod[]>>
  > {
    return http.get(`${resource}/items/methods`)
  }

  static getMerchandiseListAddress(): Promise<
    AxiosResponse<IApiResponse<IMerchandiseAddress[]>>
  > {
    return http.get(`${resource}/payment/address`)
  }

  static createPaymentMerchandise(
    body: ICreatePaymentMerchandise
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/payment/createPayment`, body)
  }

  static createQuickPaymentMerchandise(
    body: ICreateQuickPaymentMerchandise
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.post(`${resource}/payment/buyNow`, body)
  }

  static getPaymentMerchandiseCallback(
    params: any
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`${resource}/payment/callback`, {
      params
    })
  }

  static getPaymentMerchandiseOnepayCallback(
    params: any
  ): Promise<AxiosResponse<IApiResponse>> {
    return http.get(`${resource}/payment/onePayCallback`, {
      params
    })
  }

  static getPaymentMerchandiseHistory(
    params: IPramsPaymentHistory
  ): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resource}/payment/history`, {
      params
    })
  }

  static getPaymentMerchandiseTransaction({
    transaction_id
  }: {
    transaction_id: string
  }): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resource}/payment/history/${transaction_id}`, {
      params: { transaction_id }
    })
  }

  static getPaymentFeeShip(
    params: IPramsPaymentFeeShip
  ): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.post(`${resource}/payment/getFeeShip`, params)
  }

  static destroyOrder(
    params: IPramsDistroyOrder
  ): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.post(`${resource}/payment/destroyOrder`, params)
  }

  static getListVoucherMerchandise({
    item_id
  }: {
    item_id: string
  }): Promise<AxiosResponse<IApiResponse<any>>> {
    return http.get(`${resource}/vouchers/listByItem`, {
      params: { item_id }
    })
  }

  static checkoutVoucherMerchandiseInfo(
    params: ICheckVoucherMerchandise
  ): Promise<AxiosResponse<IApiResponse>> {
    const _param = removeNullKeyObject(params)
    return http.post(`${resource}/vouchers/check`, _param)
  }
}
