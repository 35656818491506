import { IconClose } from "@/app/components/icon-tsx/IconClose"
import { IconCopied } from "@/app/components/icon-tsx/IconCopied"
import { IconNonCopy } from "@/app/components/icon-tsx/IconNonCopy"
import { IconTime } from "@/app/components/icon-tsx/IconTime"
import { Button } from "@/app/components/ui/button"
import { Dialog, DialogContent } from "@/app/components/ui/dialog"
import { Skeleton } from "@/app/components/ui/skeleton"
import { useToast } from "@/app/components/ui/use-toast"
import { DATE_FORMAT, DATE_FORMAT_3, isClient } from "@/app/configs"
import { AppContext } from "@/app/context"
import { removeAttributes, removeStyle } from "@/app/helpers/function"
import { useWindowSize } from "@/app/hooks"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import {
  useQueryGetGiftcodeDetail,
  useQueryGetGiftcodeInfo
} from "@/app/services/query-fn/giftcode/giftcode-query"
import moment from "moment"
import { Trans, useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"

export const GiftcodeDetailModal = () => {
  const { t } = useTranslation()
  const {
    visibleGiftcodeDetail,
    setVisibleGiftcodeDetail,
    selectedGiftcode,
    setSelectedGiftcode,
    setVisibleUseGiftcode
  } = useContext(AppContext)
  const token = isClient ? window.localStorage.getItem("token") : null
  const router = useRouter()
  const { toast } = useToast()
  const [copied, setIsCopied] = useState<boolean>(false)
  const GAEventsTracker = useGAEventsTracker("Giftcode")
  const { data, isLoading: loadingDetail } = useQueryGetGiftcodeDetail({
    event_id: selectedGiftcode?.id
  })
  const [width] = useWindowSize()

  const {
    data: giftcodeInfo,
    error,
    isLoading
  } = useQueryGetGiftcodeInfo({
    event_id: selectedGiftcode?.id
  })

  const onCopyCode = () => {
    navigator.clipboard.writeText(
      data?.giftcode?.[0]?.code || giftcodeInfo?.[0]?.code || ""
    )
    setIsCopied(true)
  }

  useEffect(() => {
    if (!!copied) {
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }
  }, [copied])

  useEffect(() => {
    const _error: any = error
    if (_error && _error?.data?.errorCode === 33 && visibleGiftcodeDetail) {
      toast({
        variant: "error",
        title: "Giftcode đã hết. Vui lòng thử lại sau!"
      })
      setVisibleGiftcodeDetail(false)
    }
  }, [error as any, visibleGiftcodeDetail])

  const renderLoading = () => {
    return (
      <div className="flex flex-col space-y-3">
        <Skeleton className="h-[80px] w-[full] rounded-xl bg-[#EAEAEA] dark:bg-[#FFFFFF]/10" />
        <div className="space-y-2">
          <Skeleton className="h-10 w-1/2 bg-[#EAEAEA] dark:bg-[#FFFFFF]/10" />
          <Skeleton className="h-16 w-full bg-[#EAEAEA] dark:bg-[#FFFFFF]/10" />
          <Skeleton className="h-16 w-full bg-[#EAEAEA] dark:bg-[#FFFFFF]/10" />
        </div>
      </div>
    )
  }

  return (
    <Dialog open={visibleGiftcodeDetail}>
      <DialogContent
        id="modal-giftcode-detail"
        className="sm:rounded-[16px] h-auto min-h-fit gap-0 rounded-[16px] max-w-[100vw] md:max-w-[400px] p-[16px] border-0 focus-visible:outline-none dark:bg-bg-02 top-auto left-0 bottom-0 translate-x-0 md:top-[50%] md:left-[50%] md:translate-x-[-50%] translate-y-0 md:translate-y-[-50%]"
      >
        <div className="flex justify-between items-center w-full mb-[8px]">
          <p className="text-bg-01 Nunito-700 text-base mb-[40px dark:text-main-white">
            {t("reward.detail")}
          </p>
          <div
            className="w-[30px] h-[30px] rounded-[8px] flex items-center justify-center  cursor-pointer"
            onClick={() => {
              GAEventsTracker("Giftcode_close_popup")
              setVisibleGiftcodeDetail(false)
              setSelectedGiftcode({})
            }}
          >
            <IconClose />
          </div>
        </div>
        {isLoading || loadingDetail ? (
          renderLoading()
        ) : (
          <>
            <div className="flex items-center gap-[10px]">
              <img
                src={data?.game_icon}
                className="w-[48px] h-[48px] rounded-[12px]"
              />
              <div>
                <p className="text-[14px] Nunito-600 mb-1">{data?.title}</p>
                <div className="text-[10px] Nunito-500 text-main-01 dark:text-main-disable bg-bg-disable dark:bg-main-disable/10 px-[8px] h-[22px] rounded-[10px] w-fit flex items-center justify-center">
                  {data?.type === "fanpage"
                    ? "Social"
                    : data?.type === "newbie"
                    ? t("giftcode.newbie")
                    : data?.type === "vip_gamota"
                    ? t("giftcode.vip_gamota")
                    : data?.type === "mission"
                    ? t("screen.mission")
                    : t("giftcode.charge")}
                </div>
              </div>
            </div>
            <div className="my-[16px] w-full h-[1px] bg-black/10 dark:bg-white/10"></div>

            {data?.start_time && data?.end_time && (
              <div className="flex items-center text-neutral-02 dark:text-main-white text-[12px] gap-[8px]">
                <IconTime />
                <p>
                  {moment.unix(data?.start_time).format(DATE_FORMAT_3)}
                </p> -{" "}
                <p>{moment.unix(data?.end_time).format(DATE_FORMAT_3)}</p>
              </div>
            )}
            <div className="mt-[16px] flex items-center gap-[14px]">
              <div
                className={`${
                  data?.giftcode?.[0]?.code || giftcodeInfo
                    ? "bg-main-01"
                    : "bg-main-disable-02"
                } w-[4px] h-[4px] rounded-[4px] ml-[6px]`}
              ></div>
              <p
                className={`${
                  data?.giftcode?.[0]?.code || giftcodeInfo
                    ? "text-neutral-02 dark:text-neutral-06"
                    : "text-neutral-06"
                } text-[12px] flex-1`}
                dangerouslySetInnerHTML={{
                  __html: removeStyle(data?.description || "") || ""
                }}
              ></p>
            </div>

            <div className="mt-[16px] flex items-center gap-[14px]">
              <div
                className={`${
                  data?.giftcode?.[0]?.code || giftcodeInfo
                    ? "bg-main-01"
                    : "bg-main-disable-02"
                } w-[4px] h-[4px] rounded-[4px] ml-[6px]`}
              ></div>
              <p
                className={`${
                  data?.giftcode?.[0]?.code || giftcodeInfo
                    ? "text-neutral-02 dark:text-neutral-06"
                    : "text-neutral-06"
                } text-[12px] flex-1`}
                dangerouslySetInnerHTML={{
                  __html: removeStyle(data?.giftcode_description || "") || ""
                }}
              ></p>
            </div>

            <div className="mt-[16px] flex items-center gap-[8px]">
              {data?.giftcode?.[0]?.code || giftcodeInfo ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.99992 14.6668C11.6818 14.6668 14.6666 11.6821 14.6666 8.00016C14.6666 4.31826 11.6818 1.3335 7.99992 1.3335C4.31802 1.3335 1.33325 4.31826 1.33325 8.00016C1.33325 11.6821 4.31802 14.6668 7.99992 14.6668ZM11.0613 6.30716C11.2308 6.08918 11.1915 5.77504 10.9736 5.60551C10.7556 5.43598 10.4414 5.47524 10.2719 5.69322L7.60058 9.12779C7.54118 9.20416 7.42944 9.21407 7.35753 9.14935L5.66774 7.62854C5.46248 7.44381 5.14634 7.46045 4.96161 7.6657C4.77688 7.87096 4.79352 8.1871 4.99877 8.37183L6.68856 9.89264C7.19198 10.3457 7.97412 10.2763 8.38993 9.74173L11.0613 6.30716Z"
                      fill="#7673E6"
                    />
                  </svg>
                  <p className="text-[12px] text-neutral-02 dark:text-main-white">
                    {selectedGiftcode?.has_used
                      ? t("reward.used")
                      : t("giftcode.eligible")}
                  </p>
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      opacity="0.4"
                      d="M14.6666 8.50016C14.6666 12.1821 11.6818 15.1668 7.99992 15.1668C4.31802 15.1668 1.33325 12.1821 1.33325 8.50016C1.33325 4.81826 4.31802 1.8335 7.99992 1.8335C11.6818 1.8335 14.6666 4.81826 14.6666 8.50016Z"
                      fill="#7673E6"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.53219 10.739C9.72746 10.9343 10.044 10.9343 10.2393 10.739C10.4346 10.5438 10.4346 10.2272 10.2393 10.0319L8.70727 8.49992L10.2393 6.9679C10.4346 6.77264 10.4346 6.45605 10.2393 6.26079C10.044 6.06553 9.72745 6.06553 9.53218 6.26079L8.00017 7.79281L6.46806 6.2607C6.2728 6.06544 5.95622 6.06544 5.76096 6.2607C5.5657 6.45597 5.5657 6.77255 5.76096 6.96781L7.29306 8.49992L5.76095 10.032C5.56569 10.2273 5.56569 10.5439 5.76095 10.7391C5.95621 10.9344 6.27279 10.9344 6.46806 10.7391L8.00017 9.20702L9.53219 10.739Z"
                      fill="#7673E6"
                    />
                  </svg>
                  <p className="text-[12px] text-neutral-02 dark:text-main-white">
                    {t("giftcode.not_eligible")}
                  </p>
                </>
              )}
            </div>

            <div className="my-[16px]">
              {data?.giftcode?.[0]?.code || giftcodeInfo ? (
                <div className="p-[12px] border border-main-01 border-dashed rounded-[12px]">
                  <div className="flex items-center justify-center">
                    <p className="text-[16px] Nunito-700 text-main-01">
                      {data?.giftcode?.[0]?.code || giftcodeInfo?.[0]?.code}
                    </p>
                    <div className="w-[1px] h-[17px] bg-black/10 dark:text-neutral-03 opacity-30 dark:opacity-100 mx-[8px]"></div>
                    <div className="cursor-pointer w-fit" onClick={onCopyCode}>
                      {copied ? (
                        <IconCopied />
                      ) : (
                        <IconNonCopy stroke="#D9D9DB" />
                      )}
                    </div>
                  </div>

                  <p className="mt-[4px] text-center text-neutral-06 text-[10px]">
                    HSD:{" "}
                    {moment
                      .unix(
                        data?.giftcode?.[0]?.expired ||
                          giftcodeInfo?.[0]?.expired ||
                          0
                      )
                      .format(DATE_FORMAT)}
                  </p>
                </div>
              ) : (
                <p className="text-center text-neutral-06 text-[12px] px-[20px]">
                  <Trans
                    i18nKey={t("giftcode.not_eligible_desc")}
                    components={[
                      <span
                        key={0}
                        className="cursor-pointer text-main-01 Nunito-700"
                        onClick={() => {
                          router.push("/help-center")
                          setVisibleGiftcodeDetail(false)
                          setSelectedGiftcode({})
                        }}
                      ></span>
                    ]}
                  />
                </p>
              )}
            </div>

            {(data?.giftcode?.[0]?.code || giftcodeInfo) && !data?.has_use && (
              <p className="text-neutral-06 text-[12px] pb-[2px]">
                {t("giftcode.do_not_use_gc")}
              </p>
            )}

            {!selectedGiftcode?.has_used && (
              <Button
                variant="main"
                className="text-[14px] Nunito-600 h-[42px]"
                onClick={() => {
                  if (data?.giftcode?.[0]?.code || giftcodeInfo) {
                    if (data?.has_use) {
                      setVisibleUseGiftcode(true)
                      setVisibleGiftcodeDetail(false)
                      if (giftcodeInfo?.[0]?.code) {
                        setSelectedGiftcode({
                          ...data,
                          giftcode: [
                            {
                              ...giftcodeInfo?.[0]
                            }
                          ]
                        })
                      } else {
                        setSelectedGiftcode(data)
                      }
                    } else {
                      onCopyCode()
                      GAEventsTracker("Giftcode_copied")
                    }
                  } else {
                    const link =
                      data?.event_link?.includes(
                        process.env.NEXT_PUBLIC_EVENT_DOMAIN || ""
                      ) && token
                        ? `${data?.event_link}?token=${token}`
                        : data?.event_link
                    if (width < 992) {
                      window.location.href = link || ""
                    } else {
                      window.open(link)
                    }
                    GAEventsTracker("Giftcode_join_event")
                  }
                }}
              >
                {data?.giftcode?.[0]?.code || giftcodeInfo
                  ? data?.has_use
                    ? t("giftcode.use_giftcode")
                    : `${t("reward.copy")} giftcode`
                  : t("giftcode.join_event")}
              </Button>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
